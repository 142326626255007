import React from 'react'
import ReactDOM from 'react-dom/client'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import Notification from './components/shared/notification/Notification'
import './assets/css/index.css'
import 'chart.js/auto'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	<React.StrictMode>
		<Notification />
		<BrowserRouter>
			<App />
		</BrowserRouter>
	</React.StrictMode>
)
