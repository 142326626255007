import { Outlet } from 'react-router-dom'
import BusinessNavbar from "./BusinessNavbar"

const Layout = () => {
	return (
		<>
			<div style={{ minHeight: '100vh', minHeight: '100svh' }}>
				<BusinessNavbar />
				<Outlet />
			</div>
		</>
	)
}

export default Layout