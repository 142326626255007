import { useRef } from 'react'
import Logo from '../../assets/img/web/twdLogo.png'
import { HashLink } from 'react-router-hash-link'
import { Link } from 'react-router-dom'
import Close from '../../assets/img/twd/close.png'
import { RxHamburgerMenu } from 'react-icons/rx'

const BusinessNavbar = () => {
	const navRef = useRef()

	const showNavbar = () => {
		const navElement = navRef.current
		navElement.classList.toggle('responsive_nav')
	};

	return (
			<div>
				<header className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex py-3">
					<Link to="/">
						<img className="w-[235px]" src={Logo} alt="The Wellbeing Doctors Logo" />
					</Link>

					<nav ref={navRef} className="lg:flex z-50 grow">
						<div className="lg:pl-10 lg:flex-row space-y-4 lg:space-y-0 lg:space-x-6 items-center lg:items-start lg:pt-2">
							<HashLink
								onClick={showNavbar}
								smooth
								to="/#features"
								className="lg:inline-flex flex items-center justify-center text-[16px] px-1 font-medium text-gray-600 hover:text-gray-900 mb-lg-0 mb-4"
							>
								Features
							</HashLink>
							<HashLink
								onClick={showNavbar}
								smooth
								to="/#faq"
								className="lg:inline-flex flex items-center justify-center text-[16px] px-1 font-medium text-gray-600 hover:text-gray-900 mb-lg-0 mb-4"
							>
								FAQs
							</HashLink>
							<Link
								onClick={showNavbar}
								to="/blogs"
								className="lg:inline-flex flex items-center justify-center text-[16px] px-1 font-medium text-gray-600 hover:text-gray-900 mb-lg-0 mb-4"
							>
								Blogs
							</Link>
						</div>
						<a
							href="https://calendly.com/edward-l4kg/30min?month=2024-04"
							target="_blank"
							rel="noreferrer"
							className="lg:ml-auto lg:inline-flex flex justify-center items-center text-[16px] flex-shrink-0 rounded-full w-36 h-12 lg:bg-white px-4 font-normal text-gray-700 hover:text-gray-500 lg:shadow-sm"
						>
							Book a demo
						</a>

						<button className="p-1.5 top-2 absolute right-2 lg:hidden" onClick={showNavbar}>
							<img src={Close} className="text-gray-800 opacity-70 w-8" alt="Close" />
						</button>
					</nav>
					<button className="lg:hidden ml-auto" onClick={showNavbar}>
						<RxHamburgerMenu size={30} className="text-black opacity-60" />
					</button>
				</header>
			</div>
	);
};

export default BusinessNavbar


/*
<header className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 flex">
			<div className="flex-grow-1 bg-red-300">fsd</div>
		</header>


<a href="/">
				<img className="h-8 object-contain" src={Logo} alt="The Wellbeing Doctors Logo" />
			</a>

<nav ref={navRef} className="lg:flex z-50">
					<div className="lg:pl-10 lg:flex-row space-y-4 lg:space-y-0 lg:space-x-6 items-center lg:items-start lg:pt-2">
						<Link
							to="/business"
							className="lg:inline-flex flex items-center justify-center text-[16px] border-b-2 border-[#167B91] px-1 font-medium text-gray-900 hover:text-gray-700 "
						>
							Features
						</Link>
						<a
							href="https://app.thewellbeingdoctors.com/auth?redirect=/dashboard/"
							className="lg:inline-flex flex items-center justify-center text-[16px] border-b-2 border-transparent px-1 font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
						>
							Individuals
						</a>
						<HashLink
							smooth
							to="#faq"
							className="lg:inline-flex flex items-center justify-center  text-[16px] border-b-2 border-transparent px-1 font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700
								mb-lg-0 mb-4"
						>
							FAQ's
						</HashLink>
					</div>
					<div className="flex justify-center">
						<div className=" ">
							<a
								href="https://app.thewellbeingdoctors.com/manager"
								type="button"
								className="lg:inline-flex flex items-center relative text-[16px] gap-x-1.5 px-3  group-hover:bg-slate-500 
								font-medium text-gray-700  hover:text-gray-600 mt-lg-0 mt-4"
							>
								Business sign in
							</a>
							<a
								href="https://app.thewellbeingdoctors.com/auth?redirect=/dashboard/"
								className="lg:inline-flex flex justify-center items-center text-[16px] flex-shrink-0 rounded-full w-36 h-12 lg:bg-white 
								px-4 font-normal text-gray-700 hover:text-gray-500 lg:shadow-sm"
							>
								Sign in
							</a>
						</div>
					</div>

					<button className="p-1.5 top-2 absolute right-2 lg:hidden" onClick={showNavbar}>
						<img src={Close} className="text-gray-800 opacity-70 w-8" />
					</button>
				</nav>
				<button className="lg:hidden" onClick={showNavbar}>
					<RxHamburgerMenu size={30} className="text-black opacity-60" />
				</button>
*/
