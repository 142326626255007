// NOTE: hooks
import React, { lazy, Suspense } from 'react'
import { useRoutes } from 'react-router-dom'
// NOTE: components
import Loading from './components/shared/Loading'
import Layout from './components/website/Layout'
// NOTE pages
const Home = lazy(() => import('./pages/Home'))
const Explore = lazy(() => import('./pages/Explore'))
const Blog = lazy(() => import('./pages/Blog'))
const Error = lazy(() => import('./pages/404'))

const LoadPage = ({ page }) => {
	return <Suspense fallback={<Loading />}>{page}</Suspense>
}

const App = () => {
    const routing = useRoutes([
        {
            index: true,
            element: <LoadPage page={<Home />} />,
        },
        {
            path: 'blogs',
            element: <Layout />,
            children: [
                {
                    index: true,
                    element: <LoadPage page={<Explore />} />
                },
                {
                    path: ':id/:title',
                    element: <LoadPage page={<Blog />} />
                },
            ],
        },
        {
            path: '*',
            element: <LoadPage page={<Error />} />,
        },
    ]);

    return routing;
};

export default App;
